import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import {Card, CardBody } from 'reactstrap';

const SuccessMessage = () => (
    <Layout>
      <SEO title="TeamWorks"/>
        <Card>
            <CardBody className='text-center'>
              <h1>Thanks!</h1>
                <p>Your form has been successfully submitted.</p>
            </CardBody>
        </Card>
    </Layout>
  )

export default SuccessMessage